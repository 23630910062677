<template>
  <div class="page">
    <div class="search-box section">
      <el-form
          ref="searchForm"
          :inline="true"
          :model="query"
          class="demo-form-inline"
          size="medium"
      >
        <el-form-item prop="parkingId">
          <SuggestParking v-model="query.parkingId"/>
        </el-form-item>
        <el-form-item prop="leaseTitle">
          <el-input
              v-model="query.leaseTitle"
              placeholder="请输入-套餐名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="activityName">
          <el-input
              v-model="query.activityName"
              placeholder="请输入-活动名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="activityStatus">
          <el-select
              v-model="query.activityStatus"
              placeholder="请选择-活动状态"
          >
            <el-option label="未开始" value="0"></el-option>
            <el-option label="进行中" value="1"></el-option>
            <el-option label="已结束" value="2"></el-option>
            <el-option label="手动关闭" value="3"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ total }}</span
        >条信息
        </div>
        <div>
          <div class="op-extra">
            <icon-button
                icon="add-select"
                title="添加活动"
                @click.native="dialogFormVisible = true"
            />
            <icon-button
                icon="share"
                title="导出"
                @click.native="exportData"
            />
          </div>
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="tableData"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="活动名称" prop="activityName" width="180">
          </el-table-column>
          <el-table-column label="车场名称" prop="parkingName" width="180">
          </el-table-column>
          <el-table-column label="套餐名称" prop="leaseTitle" width="180">
          </el-table-column>
          <el-table-column label="开始时间" prop="beginDate" width="180">
            <template slot-scope="scope">
              {{ dateFormat(scope.row.beginDate) }}
            </template>
          </el-table-column>
          <el-table-column label="结束时间" prop="endDate" width="180">
            <template slot-scope="scope">
              {{ dateFormat(scope.row.endDate) }}
            </template>
          </el-table-column>
          <el-table-column
              label="活动价格/原价"
              prop="activityPrice,leaseInfoAmount"
              width="180"
          >
            <template slot-scope="scope">
              {{ parseFloat(scope.row.activityPrice).toFixed(2) }} /
              {{ parseFloat(scope.row.leaseInfoAmount).toFixed(2) }}
            </template>
          </el-table-column>
          <!-- <el-table-column
            prop="activityStock,stock"
            label="活动库存"
            width="180"
          >
          </el-table-column> -->
          <el-table-column label="创建人" prop="creater" width="180">
          </el-table-column>
          <el-table-column label="创建时间" prop="createTime" width="180">
            <template slot-scope="scope">
              {{ dateFormat(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="activityStatus">
            <template slot-scope="scope">
              <span>{{
                  scope.row.activityStatus === 0
                      ? "未开始"
                      : scope.row.activityStatus === 1
                          ? "进行中"
                          : scope.row.activityStatus === 2
                              ? "已结束"
                              : "手动关闭"
                }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" prop="name" width="180">
            <template slot-scope="scope">
              <el-button
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="goDetail(scope.row)"
              >详情
              </el-button
              >
              <!-- <el-button
                type="text"
                size="small"
                style="font-size: 14px"
                @click="openActivity(scope.row)"
                >开启</el-button
              > -->
              <el-button
                  v-if="
                  scope.row.activityStatus === 1
                "
                  size="small"
                  style="font-size: 14px"
                  type="text"
                  @click="closeActivity(scope.row)"
              >关闭
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination
              :current-page="pageNo"
              :page-size="pageSize"
              :total="total"
              background
              layout="sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <!-- 弹出的表单 -->
    <el-dialog
        :append-to-body="true"
        :before-close="dialogBeforeClosed"
        :visible.sync="dialogFormVisible"
        class="common-dialog"
        title="添加套餐活动"
    >
      <div v-loading="isAddLoading">
        <el-form ref="form" :model="form" :rules="rules" class="label-weight">
          <el-form-item
              :label-width="formLabelWidth"
              label="活动名称:"
              prop="activityName"
          >
            <el-col :span="16">
              <el-input
                  v-model="form.activityName"
                  autocomplete="off"
                  placeholder="请填写活动名称"
              ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item
              :label-width="formLabelWidth"
              label="选择车场:"
              prop="parkingId"
          >
            <SuggestParking v-model="form.parkingId"/>
          </el-form-item>
          <el-form-item
              :label-width="formLabelWidth"
              label="选择套餐:"
              prop="leaseTitle"
          >
            <el-select
                v-model="form.leaseTitle"
                class="select-inner"
                placeholder="请选择-套餐"
                @change="chooseLease"
            >
              <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.leaseTitle"
                  :value="{
                  value: item.id,
                  label: item.leaseTitle,
                  price: item.leasePrice,
                }"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
              :label-width="formLabelWidth"
              label="活动时间:"
              prop="activityTime"
              required
          >
            <el-date-picker
                v-model="form.activityTime"
                end-placeholder="结束日期"
                range-separator="至"
                start-placeholder="开始日期"
                type="daterange"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
              :label-width="formLabelWidth"
              label="活动价格:"
              prop="activityPrice"
          >
            <el-col :span="16">
              <el-input
                  v-model="form.activityPrice"
                  autocomplete="off"
                  placeholder="请填写活动价格"
              ></el-input>
            </el-col>
            <div v-if="showOldLeasePrice === true">
              <!-- <span class="oldLeasePrice" v-for="item in options" :key="item.id">套餐原价：{{item.leasePrice}} 元</span> -->
              <span class="oldLeasePrice"
              >套餐原价：{{ oldLeasePrice }} 元</span
              >
            </div>
          </el-form-item>
          <!-- <el-form-item label="活动库存:" :label-width="formLabelWidth">
      <el-input v-model="form.activityStock" autocomplete="off" placeholder="请输入-活动库存"></el-input>
    </el-form-item>
    <el-form-item label="限制参与次数:" :label-width="formLabelWidth">
      <el-input v-model="form.limitJoinNumber" autocomplete="off" placeholder="请输入-最大参与次数"></el-input>
    </el-form-item>
    <el-form-item label="限制购买数量:" :label-width="formLabelWidth">
      <el-input v-model="form.limitBuyNumber" autocomplete="off" placeholder="请输入-最大购买数量"></el-input>
    </el-form-item> -->
        </el-form>
        <div slot="footer" class="dialog-footer" style="text-align: center">
          <el-button @click="cancel">取 消</el-button>
          <el-button type="primary" @click="saveLeaseActivity('form')"
          >确 定
          </el-button
          >
        </div>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="exportForm" :model="exportForm" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="exportForm.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getLeaseDisCountListForPage,
  getLeaseInfoByParkingLotId,
  saveLeaseDisCount,
  startUsingOrDisable,
} from "@/api/lease";
import SuggestParking from "@/components/SuggestParking";
import {dateFormat} from "@/utils/utils";
import {doExport} from "@/api/common";
import Permission from "@/mixins/permission";

export default {
  name: "lease-activity",
  mixins: [Permission],
  data() {
    return {
      isLoading: false,
      isAddLoading: false,
      //查询条件
      query: {
        parkingId: "", //停车场名称
        leaseTitle: "", //套餐名称
        activityName: "", //活动名称
        activityStatus: "", //活动状态
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      pageNo: 1,
      pageSize: 15,
      total: 0,
      pages: 0,

      //查询到的列表数据
      tableData: [],
      exportDialogFormVisible: false, //导出弹窗
      exportForm: {
        exportSize: 1,
      },

      dialogFormVisible: false, //控制弹窗层显示
      // 添加套餐活动列表
      form: {
        activityName: "", //活动名称
        parkingId: "", //车场ID
        leaseTitle: "", //套餐名称
        beginDate: "", //活动开始时间
        endDate: "", //活动结束日期
        activityPrice: "", //活动价格
        activityTime: [],
        // activityStock: "", //活动库存
        // limitJoinNumber: "", //限制参与次数
        // limitBuyNumber: "", //限制购买数量
      },
      formLabelWidth: "120px",
      rules: {
        activityName: [{required: true, message: "活动名称不能为空"}],
        parkingId: [{required: true, message: "请选择车场"}],
        leaseTitle: [{required: true, message: "请选择套餐"}],
        activityTime: [
          {
            required: true,
            message: "活动时间不能为空",
          },
        ],
        beginDate: [
          {
            type: "date",
            required: true,
            message: "请选择开始日期",
            trigger: "change",
          },
        ],
        endDate: [
          {
            type: "date",
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        activityPrice: [
          {required: true, message: "活动价格不能为空", trigger: "blur"},
          // {  message: '活动价格不得大于套餐原价', trigger: 'blur' }
        ],
      },
      options: [], //根据车场查到的套餐选项
      oldLeasePrice: "", //套餐原价
      showOldLeasePrice: false, //显示套餐原价
    };
  },
  components: {
    SuggestParking,
  },
  //监听选中的车场，查询当前车场的套餐
  watch: {
    "form.parkingId"(newVal) {
      if (newVal !== "") {
        this.form.leaseTitle = "";
        this.oldLeasePrice = "";
        this.showOldLeasePrice = false;
        getLeaseInfoByParkingLotId(newVal).then((res) => {
          if (res && res.code === 30 && res.result) {
            this.options = res.returnObject;
          }
        });
      }
    },
  },
  methods: {
    //时间戳转换
    dateFormat(date, fmt = "yyyy-mm-dd HH:MM:SS") {
      date = !isNaN(date) ? new Date(date) : date;
      return dateFormat(fmt, date);
    },
    //查看活动信息详情
    goDetail(e) {
      this.$router.push({
        path: "/lease/activity_detail",
        query: {
          id: e.id,
        },
      });
    },
    //重置查询条件
    resetForm() {
      this.$refs.searchForm.resetFields();
      this.goSearch();
    },
    //查询长租活动列表
    goSearch() {
      this.pageNo = 1;
      this.pageSize = 10;
      this.search();
    },
    search() {
      const parkingId = this.query.parkingId;
      const leaseId = this.query.leaseTitle;
      const activityName = this.query.activityName;
      const activityStatus = this.query.activityStatus;
      const pageSize = this.pageSize;
      const pageNo = this.pageNo;
      this.isLoading = true;
      getLeaseDisCountListForPage(
          parkingId,
          pageSize,
          leaseId,
          activityName,
          activityStatus,
          pageNo
      ).then((res) => {
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.total = res.returnObject.total;
          this.pages = res.returnObject.pages;
          this.tableData = res.returnObject.records;
          this.exportForm.exportSize = res.returnObject.total;
        }
      });
    },

    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = {
        parkingId: this.query.parkingId,
        leaseId: this.query.leaseTitle,
        activityName: this.query.activityName,
        activityStatus: this.query.activityStatus,
        pageSize: this.pageSize,
        pageNo: this.pageNo,
        isExport: "1",
        exportSize: this.exportForm.exportSize,
      };

      // window.open("http://172.16.69.129:81" + '/leaseController/getLeaseDisCountListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseId=' + leaseId + '&activityName=' + activityName + '&activityStatus=' + activityStatus + '&pageNo=' + pageNo+ '&isExport=' + isExport + '&exportSize=' + exportSize);
      doExport(
          "/leaseController/getLeaseDisCountListForPage",
          params,
          "长租活动.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },

    //取消添加套餐活动
    cancel() {
      this.dialogFormVisible = false; //关闭弹窗
      //清空当前输入的信息
      this.$refs.form.resetFields();
      this.oldLeasePrice = "";
      this.showOldLeasePrice = false;
    },
    dialogBeforeClosed(done) {
      this.$refs.form.resetFields();
      this.oldLeasePrice = "";
      this.showOldLeasePrice = false;
      done();
    },

    //确定添加套餐活动
    saveLeaseActivity(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = JSON.stringify({
            activityName: this.form.activityName,
            parkingId: this.form.parkingId,
            leaseID: this.form.leaseTitle.value,
            beginDate: this.form.activityTime[0],
            endDate: this.form.activityTime[1],
            activityPrice: this.form.activityPrice,
            // activityStock: this.form.activityStock,
            // limitJoinNumber: this.form.limitJoinNumber,
            // limitBuyNumber: this.form.limitBuyNumber,
          });
          if (this.form.activityPrice > this.oldLeasePrice) {
            this.$message.error("活动价格不能大于套餐原价");
            return false;
          }
          this.isAddLoading = true;
          saveLeaseDisCount(data).then((res) => {
            this.isAddLoading = false;
            if (res && res.code === 30 && res.result) {
              this.$message.success("添加成功！");
              this.dialogFormVisible = false; //关闭弹窗
              this.$refs.form.resetFields();
              this.goSearch();
            }
          });
        } else {
          return false;
        }
      });
    },

    //关闭活动
    closeActivity(e) {
      const id = e.id;
      const status = "3";
      this.$confirm("此操作将关闭活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            startUsingOrDisable(id, status).then((res) => {
              if (res && res.code === 30 && res.result) {
                this.$message.success("关闭成功！");
                this.goSearch();
              } else {
                // try {
                //   this.$message.error(res.data);
                // } catch {
                //   this.$message.error(res.msg);
                // }
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消关闭",
            });
          });
    },
    //活动添加--选择套餐改变
    chooseLease(params) {
      const {price} = params;
      this.oldLeasePrice = price;
      this.showOldLeasePrice = true;
    },

    //开启活动
    // openActivity(e) {
    //   const id = e.id;
    //   const status = "1";
    //   this.$confirm("此操作将开启活动, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       startUsingOrDisable(id, status).then((res) => {
    //         if (res.code === 200) {
    //           this.$message.success("开启成功！");
    //         } else {
    //           // try {
    //           //   this.$message.error(res.data);
    //           // } catch {
    //           //   this.$message.error(res.msg);
    //           // }
    //         }
    //       });
    //     })
    //     .catch(() => {
    //       this.$message({
    //         type: "info",
    //         message: "已取消开启",
    //       });
    //     });
    // },
    handleSizeChange(e) {
      this.pageSize = e;
      this.search();
    },
    handleCurrentChange(e) {
      this.pageNo = e;
      this.search();
    },
  },
  mounted() {
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
.label-weight {
  /deep/ .el-form-item__label {
    font-size: 16px;
    font-weight: 600;
  }
}

.oldLeasePrice {
  margin-left: 20px;
}

// /deep/.el-dialog__body {
//   padding: 30px 30px;
// }
// /deep/.el-dialog__footer {
//   background-color: #909399;
// }
// /deep/.el-input__inner {
//   width: 86%;
// }
// .select-inner /deep/.el-input__inner {
//   width: 390px;
// }
</style>
